import React from 'react';
import { css } from '@emotion/core';
import { ContentfulContentBlock } from '../graphql-types';
import HeadingSection from './HeadingSection/HeadingSection';
import background from '../assets/images/about-science.svg';
import backgroundMobile from '../assets/images/about-science-mobile.svg';
import presets from '../styles/presets';

interface ScienceSectionProps extends ContentfulContentBlock {
  locale: 'en' | 'de';
}

const ScienceSection: React.FC<ScienceSectionProps> = ({
  locale,
  ...props
}) => {
  const minHeight = locale === 'de' ? 1278 : 978;
  const minHeightMobile = locale === 'de' ? 900 : 800;
  return (
    <section css={{ marginBottom: 124, [presets.xl]: { marginBottom: 222 } }}>
      <div
        css={css`
          background-image: url(${backgroundMobile});
          background-repeat: no-repeat;
          background-position: 50% 50%;
          background-size: cover;
          min-height: ${minHeightMobile}px;
          padding-top: 108px;

          ${presets.sm} {
            padding-top: 126px;
            background-image: url(${background});
            min-height: ${minHeight}px;
          }

          @media (min-width: 1930px) {
            padding-top: 10vw;
            min-height: 60vw;
          }
        `}
      >
        <HeadingSection {...props} />
      </div>
    </section>
  );
};

export default ScienceSection;
