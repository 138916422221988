import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Image from 'gatsby-image';
import BackgroundImage from 'gatsby-background-image';
import presets from '../../styles/presets';
import { File, ContentfulContentBlock } from '../../graphql-types';
import HeadingSection from '../HeadingSection/HeadingSection';
import AppDownloadButtons from '../common/appbuttons/AppDownloadButtonsRow';
import { Row, Col } from 'emotion-flex';
import { SYSTEM_FONTS } from '../../styles/typography';
import Container from '../Homepage/Container';
import Text from '../Homepage/Text';
import { css } from '@emotion/core';
import { ButtonLink } from '../common/Button';
import { MonoFontLabel } from '../common/typography';

export default function HeroSection(props: ContentfulContentBlock) {
  const { empty, bgImage, image } = useStaticQuery<{
    empty: File;
    bgImage: File;
    image: File;
  }>(graphql`
    {
      empty: file(relativePath: { regex: "/empty/i" }) {
        childImageSharp {
          fluid(maxWidth: 2) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      bgImage: file(relativePath: { regex: "/mission-desktop/i" }) {
        childImageSharp {
          fluid(maxWidth: 1451) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image: file(relativePath: { regex: "/mission-mobile/i" }) {
        childImageSharp {
          fluid(maxWidth: 1451) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const { prefix, title, text, callToAction } = props;

  return (
    <div
      css={{
        paddingTop: 60,
        marginTop: -12,
        marginBottom: 138,
        [presets.lg]: {
          marginBottom: 236,
        },
      }}
    >
      {/* Mobile & Tablets */}
      <div
        css={{
          marginBottom: 80,
          [presets.xl]: { display: 'none' },
        }}
      >
        <HeadingSection {...props} />
        <AppDownloadButtons />
      </div>
      <div css={{ margin: '24px 0' }} />
      {/* Background */}
      <BackgroundImage
        style={{ backgroundPosition: 'center right', backgroundSize: '1440px' }}
        fluid={[
          empty.childImageSharp.fluid,
          { ...bgImage.childImageSharp.fluid, media: '(min-width: 1200px)' },
        ]}
        css={{
          [presets.xl]: {
            minHeight: 734,
          },
        }}
      >
        <Image
          fluid={[
            image.childImageSharp.fluid,
            {
              ...bgImage.childImageSharp.fluid,
              media: '(min-width: 576px)',
            },
          ]}
          css={{
            display: 'block',
            visibility: 'visible',
            maxWidth: '100%',
            height: 'auto',
            userSelect: 'none',
            [presets.xl]: { display: 'none', visibility: 'hidden' },
          }}
        />
        <Container
          css={{ display: 'none', [presets.xl]: { display: 'block' } }}
        >
          <Row>
            <Col
              xs={12}
              xl={8}
              xlOrder={'last'}
              css={{ marginBottom: 64, [presets.xl]: { marginBottom: 0 } }}
            />
            <Col
              xs={12}
              xl={4}
              css={css`
                @media (min-width: 1415px) {
                  padding-top: 139px;
                }
              `}
            >
              <MonoFontLabel>{prefix}</MonoFontLabel>
              <h4
                css={{
                  fontFamily: ['Recoleta Alt'].concat(SYSTEM_FONTS).join(', '),
                  fontSize: 32,
                  color: '#160B2C',
                }}
                dangerouslySetInnerHTML={{ __html: title }}
              />
              <Text
                dangerouslySetInnerHTML={{
                  __html: text.childMarkdownRemark.rawMarkdownBody,
                }}
              />
              {callToAction && callToAction.url && callToAction.isDisplayed && (
                <ButtonLink to={callToAction.url}>
                  {callToAction.text}
                </ButtonLink>
              )}
            </Col>
          </Row>
        </Container>
      </BackgroundImage>
      {/* Desktop  */}
    </div>
  );
}
