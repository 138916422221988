import styled from '@emotion/styled';
import hexToRgba from 'hex-to-rgba';
import presets from '../../styles/presets';
import { SYSTEM_FONTS } from '../../styles/typography';

const Text = styled('p')({
  fontFamily: ['Open Sans'].concat(SYSTEM_FONTS).join(', '),
  fontSize: '0.875rem',
  color: hexToRgba('#160B2C', 0.8),
  [presets.md]: {
    fontSize: '1.125rem',
  },
});

export default Text;
