import React from 'react';
import { Container, Row, Col } from 'emotion-flex';
import HeadingSection from '../HeadingSection/HeadingSection';
import { ContentfulContentBlock } from '../../graphql-types';
import presets from '../../styles/presets';

interface MissionAndVision {
  mission: ContentfulContentBlock;
  vision: ContentfulContentBlock;
}

const MissionAndVision: React.FC<MissionAndVision> = ({ mission, vision }) => {
  return (
    <section css={{ marginBottom: 80, [presets.xl]: { marginBottom: 209 } }}>
      <Container>
        <Row css={{ alignItems: 'center' }}>
          <Col xs={12} lg={5} lgOffset={1}>
            <HeadingSection {...mission} variant="primary-left" />
          </Col>
          <Col xs={12} lg={5} lgOffset={1}>
            <HeadingSection {...vision} variant="primary-left" />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default MissionAndVision;
