import React from 'react';
import { graphql } from 'gatsby';
import {
  ContentfulAboutPage,
  ContentfulColorfulBoxesConnection,
  ContentfulImageLink,
} from '../graphql-types';
import SEO from '../components/SEO';
import { withLayout } from '../components/Layout';
// import PerksContainer from '../components/Perks/PerksContainer';
import Employers from '../components/Homepage/Employers';
import HeadingSection from '../components/HeadingSection/HeadingSection';
import {
  Highlights,
  ImageBelowHiring,
  MissionAndVision,
  HeroSection,
} from '../components/About';
import ScienceSection from '../components/ScienceSection';

interface Props {
  pathContext: {
    locale: 'en' | 'de';
  };
  data: {
    about: ContentfulAboutPage;
    boxes: ContentfulColorfulBoxesConnection;
    logos: { logos: ContentfulImageLink[] };
  };
}

const About: React.FC<Props> = ({
  pathContext: { locale },
  data: { about, boxes, logos },
}) => {
  return (
    <>
      <SEO lang={locale} title={about.seoTitle} />
      <main css={{ backgroundColor: '#fffdfc' }}>
        <HeroSection {...about.heroSection} />
        <Highlights {...about.highlightsSection} />
        <HeadingSection {...about.medicalSection} />
        <Employers {...about.employersSection} logos={logos.logos} />
        <HeadingSection {...about.nowHiring} />
        <ImageBelowHiring imageSmall={about.imageBelowHiringSmall} />
        <MissionAndVision
          mission={about.missionSection}
          vision={about.visionSection}
        />
        <ScienceSection {...about.scienceSection} locale={locale} />
        {/* <PerksContainer items={boxes.nodes} /> */}
      </main>
    </>
  );
};

export const aboutPageQuery = graphql`
  query aboutPage($locale: String!) {
    about: contentfulAboutPage(node_locale: { eq: $locale }) {
      seoTitle
      heroSection {
        prefix
        title
        text {
          childMarkdownRemark {
            rawMarkdownBody
          }
        }
      }
      highlightsSection {
        prefix
        title
        text {
          childMarkdownRemark {
            rawMarkdownBody
          }
        }
        items {
          heading
          shortText
        }
      }
      medicalSection {
        prefix
        title
        text {
          childMarkdownRemark {
            rawMarkdownBody
          }
        }
      }
      employersSection {
        prefix
        title
        text {
          childMarkdownRemark {
            rawMarkdownBody
          }
        }
        callToAction {
          text
          url
          isDisplayed
        }
      }
      nowHiring {
        prefix
        title
        text {
          childMarkdownRemark {
            rawMarkdownBody
          }
        }
        callToAction {
          text
          url
          isDisplayed
        }
      }
      imageBelowHiringSmall {
        fluid(maxWidth: 560) {
          ...GatsbyContentfulFluid
        }
      }
      missionSection {
        prefix
        title
        text {
          childMarkdownRemark {
            rawMarkdownBody
          }
        }
      }
      visionSection {
        prefix
        title
        text {
          childMarkdownRemark {
            rawMarkdownBody
          }
        }
      }
      scienceSection {
        prefix
        title
        text {
          childMarkdownRemark {
            rawMarkdownBody
          }
        }
        callToAction {
          text
          url
          isDisplayed
        }
      }
    }
    boxes: allContentfulColorfulBoxes(
      filter: { node_locale: { eq: $locale } }
    ) {
      nodes {
        id
        heading
        shortText
        linkHref
      }
    }
    logos: contentfulHomepage(node_locale: { eq: $locale }) {
      logos {
        id
        url
        image {
          title
          fixed(width: 120) {
            ...GatsbyContentfulFixed_withWebp
          }
        }
      }
    }
  }
`;

export default withLayout(About, { isNewLayout: true });
